<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />

    <!-- Email List -->
    <div class="email-app-list">
      <!-- App Searchbar Header -->
      <div class="app-fixed-search d-flex align-items-center">
        <!-- Toggler -->
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
            icon="MenuIcon"
            size="21"
            class="cursor-pointer"
            @click="mqShallShowLeftSidebar = true"
          />
        </div>

        <!-- Searchbar -->
        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="searchQuery"
              placeholder="Search email"
            />
          </b-input-group>
        </div>
      </div>
      <!-- Emails List -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="email-user-list scroll-area"
      >
        <div class="d-block d-lg-none bg-white p-1">
          <feather-icon
            icon="MenuIcon"
            size="21"
            class="cursor-pointer"
            @click="mqShallShowLeftSidebar = true"
          />
        </div>
        <aom-skeleton-loader v-if="isLoading" />
        <ul class="email-media-list">
          <b-media
            v-for="email in emailList"
            :key="email.id"
            tag="li"
            no-body
            :class="{ 'mail-read': email.isRead }"
            @click="viewTopicMessage(email.id)"
          >
            <b-media-body>
              <div class="mail-details">
                <div
                  class="mail-items"
                >
                  <h5 class="m-0">
                    <img
                      v-if="email.created_by.profile_image && email.created_by.profile_image.url"
                      :src="email.created_by.profile_image.url"
                      alt="profile_image"
                      class="rounded-circle"
                      style="width: 2.5rem; height: 2.5rem"
                    >
                    <b-avatar
                      v-else
                      :text="userInitials(email.created_by)"
                      size="2.5rem"
                    />
                    <span class="d-none d-sm-inline font-weight-bold">
                      {{ email.created_by.full_name }}
                    </span>
                    <span class="d-none d-sm-inline ml-1">
                      {{ email.latest_message[0].subject }}
                    </span>
                    <!-- For Mobile -->
                    <p class="d-block d-sm-none font-weight-bold mt-1">
                      {{ email.created_by.full_name }}
                    </p>
                    <p class="d-block d-sm-none">
                      {{ email.latest_message[0].subject }}
                    </p>
                  </h5>
                  <span class="text-truncate">{{ email.name }}</span>
                </div>
                <div class="mail-type">
                  <b-badge :variant="['Match', 'Direct'].indexOf(getTopicLabel(email, matchedUsers)) !== -1? 'light-primary': 'light-success'">
                    {{ getTopicLabel(email, matchedUsers) }}
                  </b-badge>
                </div>
                <div class="mail-meta-item">
                  <span class="mail-date">{{ formatDate(email.updated_at) }}</span>
                </div>
              </div>

              <div
                v-if="email.messages"
                class="mail-message"
              >
                <p class="text-truncate mb-0">
                  {{ email.messages.map(m => m.subject).join('') }}
                </p>
              </div>
            </b-media-body>
          </b-media>
        </ul>
        <div
          class="no-results"
          :class="{'show': !emailList.length && !isLoading}"
        >
          <h5>No Items Found</h5>
        </div>
      </vue-perfect-scrollbar>
    </div>

    <!-- Email View/Detail -->
    <email-view
      :class="{'show': showEmailDetails}"
      :is-loading="isLoadingMsg"
      :email-view-data="emailViewData"
      :recipients="emailRecipients"
      @close-email-view="showEmailDetails = false"
      @emailAdded="viewTopicMessage"
    />

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <email-left-sidebar
        :shall-show-email-compose-modal.sync="shallShowEmailComposeModal"
        :class="{'show': mqShallShowLeftSidebar}"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
      />
    </portal>
    <!-- Compose Email Modal -->
    <email-compose
      v-model="shallShowEmailComposeModal"
      :is-program-communications="true"
      @emailAdded="fetchProgramEmails"
    />
  </div>
</template>

<script>
import {
  ref
} from '@vue/composition-api';
import {
  BMedia, 
  BMediaBody, 
  BAvatar, 
  BBadge,
  BInputGroupPrepend,
  BInputGroup,
  BFormInput
} from 'bootstrap-vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import {  formatDateToMonthShort } from '@core/utils/filter';
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app';
import EmailLeftSidebar from './EmailLeftSidebar.vue';
import EmailView from './EmailView.vue';
import { commsService } from '@services';
import AomSkeletonLoader from '@aom-core/AomSkeletonLoader.vue';
import { makeErrorToast} from "@/libs/utils";
import { recipientTypes } from '@models';
import _debounce from "lodash/debounce";
import EmailCompose from "./EmailCompose.vue";
import { formatDateTimeFromIsoDateTime } from "@/libs/utils";
import useCommunications from '@/views/apps/useCommunications';
import { usersService } from '@/services';

export default {
  components: {
    BMedia,
    BMediaBody,
    VuePerfectScrollbar,
    EmailLeftSidebar,
    EmailView,
    EmailCompose,
    AomSkeletonLoader,
    BAvatar,
    BBadge,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput
  },
  data() {
    return {
      isLoading: false,
      isLoadingMsg: false,
      emailList: [],
      matchedUsers: [],
      showEmailDetails: false,
      emailViewData: [],
      emailRecipients: [],
      emailsTotal: 0,
      searchQuery:undefined,
      topic: {},
    };
  },
  computed: {
    appCurrentProgram() {
      return this.$store.getters['app/currentProgram'];
    },
    profile() {
      return this.$store.getters["profile/profile"];
    },
  },
  watch: {
    '$route.query': {
      handler: function() {
        if (this.$route.query.userId) {
          this.shallShowEmailComposeModal = true;
        }
      },
      deep: true,
      immediate: true
    },
    searchQuery: _debounce(function (value) {
      this.fetchProgramEmails({
        columnFilters: [{
          field: 'messages',
          value: value,
        }],
      });
    }, 1000),
  },
  created () {
    this.fetchProgramEmails();
    this.fetchListUserMatches();
  },
  methods: {
    async fetchProgramEmails(searchQuery) {
      try {
        this.isLoading = true;
        const programId = this.appCurrentProgram.id;
        const response = await commsService.getProgramsComms(
          programId,
          {columnFilters: [{"field":"created_by_id","value":this.profile.id}].concat(searchQuery?.columnFilters ?? [])}
        );
        const {items, total} = response.data;
        this.emailList = items.sort(function compare(a, b) {
          return new Date(b.updated_at) - new Date(a.updated_at);
        });
        this.emailsTotal = total;
      } catch (e) {
        console.log(e);
        this.$toast(makeErrorToast("Program Emails Not Loaded"));
      } finally {
        this.isLoading = false;
      }
    },

    async fetchListUserMatches() {
      try {
        const programId = this.appCurrentProgram.id;
        const response = await usersService.getListUserMatches(programId, this.profile.id);
        const {items } = response.data;
        this.matchedUsers = items.map(i => {
          let users = i.match_users.filter(i => i.user_id !== this.profile.id);
          return users.length? users[0]: null;
        });
      } catch (e) {
        this.$log.error(e);
      }
    },
    async viewTopicMessage (topicId) {
      try {
        this.isLoadingMsg = true;
        this.showEmailDetails = true;
        const programId = this.appCurrentProgram.id;
        const response = await commsService.getTopicMessageList(programId, topicId);
        const {items} = response.data;
        this.emailViewData = items;
        const topic = this.emailList.find(topic => topic.id === topicId);
        this.topic = topic;
        this.emailRecipients = this.emailList.filter(e => e.id === topicId);
      } catch (e) {
        console.log(e);
        this.$toast(makeErrorToast("Could not fetch Email."));
      } finally {
        this.isLoadingMsg = false;
      }
    },
    filterEmailList() {
      _debounce(() => {
        this.fetchProgramEmails(searchQuery);
      }, 1000);
    },
    formatDate(date) {
      return formatDateTimeFromIsoDateTime(date, true, 'MMM DD, HH:mm');
    },
    userInitials(user) {
      const firstChar = user?.first_name ? user?.first_name[0] : '';
      const lastChar = user?.last_name ? user?.last_name[0] : '';
      return `${firstChar}${lastChar}`;
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    };
    // Compose
    const shallShowEmailComposeModal = ref(false);

    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();

    const { getTopicLabel } = useCommunications();

    return {
      perfectScrollbarSettings,
      formatDateToMonthShort,
      shallShowEmailComposeModal,
      mqShallShowLeftSidebar,
      recipientTypes,
      getTopicLabel
    };
  },
};
</script>

<style lang="scss" scoped>
@use "sass:map";
@import '~@/assets/scss/base/bootstrap-extended/_variables.scss';

.mail-type {
  margin-right: 20px;
  margin-left: auto;
}

@media (min-width: #{map.get($container-max-widths, "md")}) {
  .email-app-list {
    background-color: #fff;
  }
}
</style>

<style lang="scss">
@import "~@/assets/scss/base/pages/app-email.scss";
</style>
