import { recipientTypes, userRoles } from "@/models";

export default function useCommunications() {

  const getTopicLabel = (topic, matchedUsers) => {
    
    if (topic.is_schedulable) {
      return 'Program';
    }
    if (topic.created_by) {
      const senderIsParticipant = topic.created_by.user_roles.filter(r => {
        return [userRoles.MENTOR, userRoles.MENTEE].indexOf(r.role_id) !== -1;
      }).length > 0;
      const recipientHasChampionOrAdmin = topic.topic_recipients.filter(r => {
        if (r.recipient_type === recipientTypes.USER) {
          return r.recipient?.user_roles?.filter(role => {
            return [userRoles.CHAMPION, userRoles.PROGRAM_ADMIN, userRoles.ADMIN].indexOf(role.role_id) !== -1;
          }).length > 0;
        }
        return false;
      }).length > 0;
      if (senderIsParticipant && recipientHasChampionOrAdmin) {
        return 'Program';
      }
      if (senderIsParticipant) {
        const recipientHasMatchedParticipant = topic.topic_recipients.filter(r => {
          if (r.recipient_type === recipientTypes.USER) {
            return matchedUsers.filter(u => u.user_id == r.recipient_id).length > 0; 
          }
          return false;
        }).length > 0;
        if (recipientHasMatchedParticipant) {
          return 'Match';
        }
        return 'Direct';
      }
    }
    return 'Program';
  };

  return {
    getTopicLabel
  };
}