<template>
  <b-card no-body>
    <b-card-header
      class="email-detail-head"
    >
      <div class="user-details d-flex justify-content-between align-items-center flex-wrap">
        <b-avatar
          size="48"
          :text="userInitials(message.user)"
          :src="userProfileImage"
          class="mr-75"
        />
        <div class="mail-items">
          <h5 class="mb-0">
            {{ getUserFullName(message) }}
          </h5>
          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0"
            class="email-info-dropup"
          >
            <template #button-content>
              <span
                class="font-small-3 text-muted mr-25 d-inline-block text-truncate"
                style="max-width: 500px"
              >
                {{ `Sent to ${recipientAll}` }}
              </span>
              <feather-icon
                icon="ChevronDownIcon"
                size="10"
              />
            </template>
            <table class="table table-sm table-borderless font-small-3">
              <tbody>
                <tr>
                  <td class="text-right text-muted align-top">
                    Date:
                  </td>
                  <td>{{ formatDate(message.created_at) }}</td>
                </tr>
                <tr v-if="message.user">
                  <td class="text-right text-muted align-top">
                    From:
                  </td>
                  <td>{{ getUserFullName(message) }}</td>
                </tr>
                <tr>
                  <td class="text-right text-muted align-top">
                    Sent to:
                  </td>
                  <td v-if="isSentToProgramManger">
                    <tr>
                      <td class="p-0">
                        Program Manager
                      </td>
                    </tr>
                  </td>
                  <td v-if="isUserEmail && !isSentToProgramManger">
                    <tr
                      v-for="user in recipentUserList"
                      :key="user"
                    >
                      <td class="p-0">
                        {{ user }}
                      </td>
                    </tr>
                  </td>
                  <td v-if="isGroupEmail && !isSentToProgramManger">
                    <tr
                      v-for="groupUser in recipientGroupList"
                      :key="groupUser"
                    >
                      <td class="p-0">
                        {{ groupUser }}
                      </td>
                    </tr>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-dropdown>
        </div>
      </div>
      <div class="mail-meta-item d-flex align-items-center">
        <small class="mail-date-time text-muted">{{ formatDate(message.created_at) }}</small>
      </div>
    </b-card-header>
  
    <b-card-body class="mail-message-wrapper pt-2">
      <!-- eslint-disable vue/no-v-html -->
      <div
        class="mail-message"
        v-html="message.body"
      />
      <!-- eslint-enable -->
    </b-card-body>
  </b-card>
</template>
  
<script>
import {
  BDropdown, BCard, BCardHeader, BCardBody, BAvatar
} from 'bootstrap-vue';
  import { recipientTypes } from '@models';
import { formatDateTimeFromIsoDateTime } from "@/libs/utils";
import { userRoles, userStatus } from '@/models';
import { isCommsBetweenProgramManagerAndParticipant } from "@/@core/utils/utils";
import { senderIsChampionOrAdmin } from "@/@core/utils/utils";

export default {
  components: {
    BDropdown, BCard, BCardHeader, BCardBody, BAvatar
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    recipients: {
      type: Array,
      required: true,
      default: () => [],
    } 
  },
  computed: {
    profile() {
      return this.$store.getters["profile/profile"];
    },
    recipentUserList() {
      return this.recipients
        .flatMap(receiver => 
          receiver.topic_recipients
            .filter(t => t.recipient_id !== this.message.user.id)  // Filter out the unwanted topic_recipient
            .flatMap(t => t.recipient_type === recipientTypes.USER && t.recipient  
              ? `${t.recipient.first_name} ${t.recipient.last_name}` 
              : t.raw_email_address
            )
        );
    },
    recipientGroupList() {
      return this.recipients.flatMap(receiver => receiver.topic_recipients.flatMap(t => t.recipient.name));
    },
    isUserEmail() {
      return this.recipients.filter(r => r.topic_recipients.some(t => t.recipient_type === recipientTypes.USER )).length > 0;
    },
    isGroupEmail() {
      return this.recipients.filter(r => r.topic_recipients.some(t => t.recipient_type === recipientTypes.GROUPS )).length > 0;
    },
    recipientAll() {
      if (this.isSentToProgramManger) {
        return 'Program Manager';
      }
      const topic_owner = this.message.user.id !== this.message.topic.created_by.id &&  `${this.message.topic.created_by.first_name} ${this.message.topic.created_by.last_name}` 
      return [...this.recipentUserList, ...this.recipientGroupList, topic_owner].filter(r => r).join(", ");
    },
    isSentToProgramManger() {
      return isCommsBetweenProgramManagerAndParticipant(this.message.topic, this.message);
    },
    userProfileImage() {
      return this.message.user.profile_image?.url || '';
    },
  },
  methods: {
    formatDate(date) {
      return formatDateTimeFromIsoDateTime(date, true, 'MMM DD, HH:mm');
    },
    userInitials(userName) {
      const firstInt = userName? Array.from(userName?.first_name)[0]: '';
      const lastInt = userName? Array.from(userName?.last_name)[0]: '';
      return `${firstInt}${lastInt}`;
    },
    getUserFullName(message) {
      if (message.user) {
        if (senderIsChampionOrAdmin(message.user.user_roles)) {
          return "Champion"; 
        }
        const roles = message.user.user_roles.filter(r => {
          return [userRoles.CHAMPION_CANDIDATE, userRoles.PARTICIPANT_CANDIDATE].indexOf(r.role_id) == -1;
        });
        if (roles.length === 0) {
          return "User Removed";
        } else {
          const isActive = roles.filter(r => r.status_id === userStatus.ACTIVE).length > 0;
          if (!isActive) {
            return "User Deactivated";
          }
        }
        return `${message.user.full_name}`;
      }
      return message.sender_id !== null? "User Removed": "";
    },
  },
  setup() {
    return {
      recipientTypes
    };
  },
};
</script>
